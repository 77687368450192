import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import { isLoggedIn } from '../src/libs/browser'
import { URL_TEMPLATE } from '../src/libs/constant'

const Index = () => {
  const router = useRouter()

  useEffect(() => {
    if (isLoggedIn()) {
      router.push(URL_TEMPLATE.SYSTEM_DASHBOARD)
    } else {
      router.push(URL_TEMPLATE.SYSTEM_LOGIN)
    }
  })

  return <></>
}

export default Index
